// stocks/client/src/components/StockChart.tsx

import React, { useState, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { StockData } from '../types';
import { Modal, Box, Typography } from '@mui/material';

interface StockChartProps {
  data: StockData[];
  timeRange: string;
  customStartDate?: string;
  customEndDate?: string;
}

interface ExtendedMouseEvent extends React.MouseEvent<SVGCircleElement, MouseEvent> {
  payload?: StockData;
}

interface ChartDataPoint {
  date: string;
  [key: string]: string | number | null;
}

const StockChart: React.FC<StockChartProps> = ({ data, timeRange, customStartDate, customEndDate }) => {
  const [modalData, setModalData] = useState<StockData | null>(null);

  const processedData = useMemo(() => {
    // Filter data based on time range
    const now = new Date();
    let startDate: Date, endDate: Date;

    switch (timeRange) {
      case 'week':
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        endDate = now;
        break;
      case 'month':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
        endDate = now;
        break;
      case 'custom':
        if (customStartDate && customEndDate) {
          startDate = new Date(customStartDate);
          endDate = new Date(customEndDate);
        } else {
          return [];
        }
        break;
      default:
        startDate = new Date(0); // Beginning of time
        endDate = now;
    }

    // First sort and filter the data chronologically
    const sortedData = [...data]
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .filter(item => {
        const itemDate = new Date(item.date);
        return itemDate >= startDate && itemDate <= endDate;
      });
    
    if (sortedData.length === 0) return [];

    // Get the date range
    const firstDate = new Date(sortedData[0].date);
    const lastDate = new Date(sortedData[sortedData.length - 1].date);
    
    // Create array of all dates in range
    const allDates: string[] = [];
    for (let d = new Date(firstDate); d <= lastDate; d.setDate(d.getDate() + 1)) {
      allDates.push(d.toISOString().split('T')[0]);
    }

    // Create a map of existing data by date and source
    const dataByDateAndSource: Record<string, Record<string, number>> = {};
    sortedData.forEach(item => {
      if (!dataByDateAndSource[item.date]) {
        dataByDateAndSource[item.date] = {};
      }
      dataByDateAndSource[item.date][item.source] = item.sentiment;
    });

    // Generate complete dataset with nulls for missing dates
    return allDates.map(date => {
      const entry = {
        date,
        ...Object.fromEntries(
          Array.from(new Set(data.map(d => d.source))).map(source => [
            source,
            dataByDateAndSource[date]?.[source] ?? null
          ])
        )
      };
      return entry;
    });
  }, [data, timeRange, customStartDate, customEndDate]);

  const handleClick = (event: ExtendedMouseEvent) => {
    if (event.payload) {
      setModalData(event.payload);
    }
  };

  const handleCloseModal = () => {
    setModalData(null);
  };

  const sources = Array.from(new Set(data.map(d => d.source)));
  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088FE'];

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={processedData}
          margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date"
            interval={0}
            tickFormatter={(value) => {
              const date = new Date(value);
              return date.toLocaleDateString('en-US', {
                month: 'numeric',
                day: 'numeric'
              });
            }}
            angle={-45}
            textAnchor="end"
            height={60}
          />
          <YAxis domain={[-100, 100]} />
          <Tooltip 
            formatter={(value: any) => {
              if (value === null) return ['No data'];
              return [value.toFixed(2), 'Sentiment'];
            }}
            labelFormatter={(label: string) => {
              const date = new Date(label);
              return date.toLocaleDateString('en-US', {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric'
              });
            }}
          />
          <Legend />
          {sources.map((source, index) => (
            <Line
              key={source}
              type="monotone"
              dataKey={source}
              name={source}
              stroke={colors[index % colors.length]}
              activeDot={{ r: 8, onClick: handleClick }}
              connectNulls={false}
              dot={{ r: 4 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
      <Modal
        open={modalData !== null}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Data Point Information
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Date: {modalData ? new Date(modalData.date).toLocaleDateString('en-US', {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric'
            }) : ''}<br />
            Sentiment: {modalData?.sentiment.toFixed(2)}<br />
            Source: {modalData?.source}<br />
            Link: <a href={modalData?.link} target="_blank" rel="noopener noreferrer">{modalData?.link}</a>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default StockChart;