// /Users/jamesknudsen/Documents/GitHub/stocks/client/src/services/api.ts

import { Stock, StockData, BotData } from '../types';

// API Configuration
const USE_LOCAL_API = false;
const LOCAL_API = 'http://localhost:3001/api';
const REMOTE_API = 'https://stocks-jns3.onrender.com/api';
const API_BASE_URL = USE_LOCAL_API ? LOCAL_API : REMOTE_API;

// Retry Configuration
const MAX_RETRIES = 5;
const INITIAL_RETRY_DELAY = 1000;
const MAX_RETRY_DELAY = 10000;

const logApiCall = (endpoint: string, data?: any) => {
    console.log(`[API ${USE_LOCAL_API ? 'LOCAL' : 'REMOTE'}] ${endpoint}`, data || '');
};

const logApiError = (endpoint: string, error: any, attempt?: number) => {
    console.error(`[API ERROR ${USE_LOCAL_API ? 'LOCAL' : 'REMOTE'}] ${endpoint}:`, 
        attempt ? `Attempt ${attempt}/${MAX_RETRIES}: ` : '', error);
};

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const getRetryDelay = (attempt: number) => {
    const exponentialDelay = Math.min(
        INITIAL_RETRY_DELAY * Math.pow(2, attempt - 1),
        MAX_RETRY_DELAY
    );
    // Add some jitter to prevent thundering herd
    return exponentialDelay + Math.random() * 1000;
};

const fetchWithRetry = async <T>(
    endpoint: string,
    options: RequestInit = {},
    attempt = 1
): Promise<T> => {
    try {
        const response = await fetch(`${API_BASE_URL}${endpoint}`, {
            ...options,
            headers: {
                'Content-Type': 'application/json',
                ...options.headers,
            },
        });

        if (response.status === 503) {
            if (attempt < MAX_RETRIES) {
                const retryDelay = getRetryDelay(attempt);
                logApiError(endpoint, `Service unavailable, retrying in ${retryDelay}ms`, attempt);
                await delay(retryDelay);
                return fetchWithRetry(endpoint, options, attempt + 1);
            }
            throw new Error('Service unavailable after maximum retries');
        }

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data as T;
    } catch (error) {
        if (error instanceof Error && error.message.includes('fetch')) {
            if (attempt < MAX_RETRIES) {
                const retryDelay = getRetryDelay(attempt);
                logApiError(endpoint, `Network error, retrying in ${retryDelay}ms`, attempt);
                await delay(retryDelay);
                return fetchWithRetry(endpoint, options, attempt + 1);
            }
        }
        throw error;
    }
};

export const fetchStocks = async (): Promise<Stock[]> => {
    const endpoint = '/stocks';
    logApiCall(`Fetching stocks from: ${API_BASE_URL}${endpoint}`);
    
    try {
        const data = await fetchWithRetry<Stock[]>(endpoint);
        logApiCall('Successfully fetched stocks:', data.length);
        return data;
    } catch (error) {
        logApiError(endpoint, error);
        throw new Error(`Failed to fetch stocks: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
};

export const fetchStockData = async (): Promise<Record<string, StockData[]>> => {
    const endpoint = '/stock-data';
    logApiCall(`Fetching stock data from: ${API_BASE_URL}${endpoint}`);
    
    try {
        const data = await fetchWithRetry<Record<string, StockData[]>>(endpoint);
        
        // Log data summary
        const totalEntries = Object.values(data).reduce((sum, arr) => sum + arr.length, 0);
        const allDates = Object.values(data)
            .flat()
            .map(entry => new Date(entry.date))
            .sort((a, b) => a.getTime() - b.getTime());

        console.log('[DATA] Stock data summary:', {
            stocks: Object.keys(data).length,
            totalEntries,
            dateRange: allDates.length ? {
                first: allDates[0].toISOString().split('T')[0],
                last: allDates[allDates.length - 1].toISOString().split('T')[0]
            } : 'No data'
        });

        return data;
    } catch (error) {
        logApiError(endpoint, error);
        throw error;
    }
};

export const fetchBotData = async (): Promise<Record<string, BotData[]>> => {
    const endpoint = '/bot-data';
    logApiCall(`Fetching bot data from: ${API_BASE_URL}${endpoint}`);
    
    try {
        return await fetchWithRetry<Record<string, BotData[]>>(endpoint);
    } catch (error) {
        logApiError(endpoint, error);
        throw new Error(`Failed to fetch bot data: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
};

export const checkApiStatus = async (): Promise<boolean> => {
    try {
        await fetchWithRetry('/stocks', { method: 'HEAD' });
        return true;
    } catch (error) {
        return false;
    }
};

export const apiConfig = {
    isLocal: USE_LOCAL_API,
    baseUrl: API_BASE_URL,
    setLocal: () => {
        (window as any).USE_LOCAL_API = true;
        console.log('Switched to local API');
    },
    setRemote: () => {
        (window as any).USE_LOCAL_API = false;
        console.log('Switched to remote API');
    }
};