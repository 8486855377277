// stocks/client/src/components/AllStocksChart.tsx

import React, { useMemo, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { 
  ScatterChart, 
  Scatter, 
  XAxis, 
  YAxis, 
  ReferenceLine,
  Tooltip, 
  Legend, 
  ResponsiveContainer 
} from 'recharts';
import type { Payload } from 'recharts/types/component/DefaultLegendContent';
import { StockData } from '../types';
import { Modal, Box, IconButton, Typography, Switch, FormControlLabel, Alert, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

const CUTOFF_DATE = '2024-10-29';

const StyledLegendContainer = styled('div')({
  '.recharts-legend-wrapper': {
    '.recharts-default-legend': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      padding: 0,
      margin: 0,
      listStyle: 'none',
    },
    '.recharts-legend-item': {
      padding: '4px 8px',
      margin: '2px',
      display: 'inline-flex',
      alignItems: 'center'
    }
  }
});

interface AllStocksChartProps {
  data: Record<string, StockData[]>;
  timeRange: string;
  customStartDate: string;
  customEndDate: string;
}

interface ProcessedDataPoint {
  stock: string;
  sentiment: number;
  date: string;
  timestamp: number;
  displayDate: string;
  source: string;
  link: string;
  hasValidLinks: boolean;
}

interface ModalContent {
  stock: string;
  date: string;
  sentiment: number;
  source: string;
  link: string;
  hasValidLinks: boolean;
}

const BLUE_SHADES = [
  '#001F3F', '#0074D9', '#7FDBFF', '#39CCCC', 
  '#3D9970', '#2ECC40', '#01FF70', '#FFDC00'
];

const BACKGROUND_OPACITY = 0.1;
const HIGHLIGHT_OPACITY = 0.8;
const DOT_OPACITY = 1;
const LINE_OPACITY = 0.6;
const BACKGROUND_COLOR = '#9ca3af';
const POSITIVE_COLOR = '#157f3d';
const NEGATIVE_COLOR = '#991b1b';
const SELECTED_COLOR = '#3b82f6';

const CustomYAxisTick = (props: any) => {
  const { x, y, payload } = props;
  let label = `${payload.value}`;
  if (payload.value === 100) label = "100 (Bullish)";
  if (payload.value === -100) label = "-100 (Bearish)";
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-5} y={0} dy={4} textAnchor="end" fill="#666" fontSize="0.7rem">
        {label}
      </text>
    </g>
  );
};

const AllStocksChart = forwardRef<{ clearSelectedStocks: () => void }, AllStocksChartProps>(({ 
  data, 
  timeRange, 
  customStartDate, 
  customEndDate,
}, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  console.log('AllStocksChart render:', {
    dataKeys: Object.keys(data),
    dataLength: Object.keys(data).length,
    timeRange,
    customStartDate,
    customEndDate
  });

  const [chartMargins, setChartMargins] = useState({ 
    top: 20, 
    right: 100, 
    bottom: 40, 
    left: 60 
  });

  const [selectedStocks, setSelectedStocks] = useState<string[]>([]);
  const [showDefaultTrends, setShowDefaultTrends] = useState(true);
  const [orderByValue, setOrderByValue] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContent | null>(null);
  const [showLinkWarning, setShowLinkWarning] = useState(true);

  useEffect(() => {
    const updateDimensions = () => {
      if (isMobile) {
        setChartMargins({ top: 20, right: 10, bottom: 120, left: 40 });
      } else if (isTablet) {
        setChartMargins({ top: 20, right: 60, bottom: 80, left: 50 });
      } else {
        setChartMargins({ top: 20, right: 100, bottom: 40, left: 60 });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [isMobile, isTablet]);

  useImperativeHandle(ref, () => ({
    clearSelectedStocks: () => {
      setSelectedStocks([]);
      setShowDefaultTrends(false);
    }
  }));

  const stockStats = useMemo(() => {
    const now = new Date();
    let startDate: Date, endDate: Date;

    switch (timeRange) {
      case 'week':
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        endDate = now;
        break;
      case 'month':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
        endDate = now;
        break;
      case 'custom':
        startDate = new Date(customStartDate);
        endDate = new Date(customEndDate);
        break;
      default:
        startDate = new Date(0);
        endDate = now;
    }

    const stockSummaries = Object.entries(data).map(([stock, values]) => {
      const filteredValues = values.filter(val => {
        const valueDate = new Date(val.date);
        return valueDate >= startDate && valueDate <= endDate;
      });

      return {
        stock,
        avgSentiment: filteredValues.length >= 2 ? 
          filteredValues.reduce((acc, val) => acc + val.sentiment, 0) / filteredValues.length : 
          null,
        dataPoints: filteredValues.length
      };
    });

    const validStocks = stockSummaries
      .filter(s => s.dataPoints >= 2 && s.avgSentiment !== null)
      .sort((a, b) => (b.avgSentiment || 0) - (a.avgSentiment || 0));

    const topTwo = validStocks.slice(0, 2);
    const bottomTwo = validStocks.slice(-2);
    
    return {
      stats: validStocks,
      topPerformers: topTwo.map(s => s.stock),
      bottomPerformers: bottomTwo.map(s => s.stock),
      stockToAvg: Object.fromEntries(validStocks.map(s => [s.stock, s.avgSentiment]))
    };
  }, [data, timeRange, customStartDate, customEndDate]);

  const getOrderedStocks = useMemo(() => {
    return Object.keys(data).sort((a, b) => {
      if (orderByValue) {
        const aValue = stockStats.stockToAvg[a] || 0;
        const bValue = stockStats.stockToAvg[b] || 0;
        return bValue - aValue; // High to low
      }
      return a.localeCompare(b); // Alphabetical
    });
  }, [data, orderByValue, stockStats.stockToAvg]);

  const processedData = useMemo(() => {
    const cutoffTimestamp = new Date(CUTOFF_DATE).getTime();

    const allData: ProcessedDataPoint[] = Object.entries(data).flatMap(([stock, stockData]) =>
      stockData
        .filter(d => !d.date.includes('10/30'))
        .map(d => {
          let date: Date;
          if (d.date.includes('-')) {
            date = new Date(d.date);
          } else {
            const [month, day, year] = d.date.split('/').map(num => parseInt(num));
            date = new Date(year >= 2000 ? year : 2000 + year, month - 1, day);
          }

          const hasValidLinks = date.getTime() >= cutoffTimestamp;

          return {
            stock,
            sentiment: d.sentiment,
            date: date.toISOString().split('T')[0],
            timestamp: date.getTime(),
            displayDate: date.toLocaleDateString('en-US', {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric'
            }),
            source: d.source,
            link: hasValidLinks ? d.link : '',
            hasValidLinks
          };
        })
    );
    
    return allData.sort((a, b) => a.timestamp - b.timestamp);
  }, [data]);

  const filteredData = useMemo(() => {
    if (timeRange === 'all') return processedData;

    const now = new Date();
    let startDate: Date, endDate: Date;

    switch (timeRange) {
      case 'week':
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        endDate = now;
        break;
      case 'month':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
        endDate = now;
        break;
      case 'custom':
        startDate = new Date(customStartDate);
        endDate = new Date(customEndDate);
        break;
      default:
        return processedData;
    }

    return processedData.filter(item => {
      const itemDate = new Date(item.date);
      return itemDate >= startDate && itemDate <= endDate;
    });
  }, [processedData, timeRange, customStartDate, customEndDate]);

  const getStockColor = (stock: string): string => {
    if (selectedStocks.includes(stock)) {
      const index = selectedStocks.indexOf(stock) % BLUE_SHADES.length;
      return BLUE_SHADES[index];
    }
    if (stockStats.topPerformers.includes(stock) && showDefaultTrends) {
      return POSITIVE_COLOR;
    }
    if (stockStats.bottomPerformers.includes(stock) && showDefaultTrends) {
      return NEGATIVE_COLOR;
    }
    return BACKGROUND_COLOR;
  };

  const shouldShowTrendLine = (stock: string): boolean => {
    const isDefault = (stockStats.topPerformers.includes(stock) || 
                      stockStats.bottomPerformers.includes(stock));
    return selectedStocks.includes(stock) || (isDefault && showDefaultTrends);
  };

  const getOpacity = (stock: string): number => {
    if (selectedStocks.includes(stock) || 
        ((stockStats.topPerformers.includes(stock) || stockStats.bottomPerformers.includes(stock)) && showDefaultTrends)) {
      return DOT_OPACITY;
    }
    return BACKGROUND_OPACITY;
  };

  const handleLegendClick = (entry: Payload) => {
    if (entry && typeof entry.value === 'string') {
      const stock = entry.value;
      const isDefaultStock = stockStats.topPerformers.includes(stock) || 
                            stockStats.bottomPerformers.includes(stock);
      
      if (!isDefaultStock || !showDefaultTrends) {
        const newSelectedStocks = selectedStocks.includes(stock)
          ? selectedStocks.filter(s => s !== stock)
          : [...selectedStocks, stock];
        
        setSelectedStocks(newSelectedStocks);
      }
    }
  };

  const handleDataPointClick = (point: ProcessedDataPoint) => {
    setModalContent({
      stock: point.stock,
      date: point.displayDate,
      sentiment: point.sentiment,
      source: point.source,
      link: point.link,
      hasValidLinks: point.hasValidLinks
    });
  };

// If there's no data, show a message
if (Object.keys(data).length === 0) {
  return (
    <Box sx={{ 
      height: '100%', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center' 
    }}>
      <Typography color="text.secondary">
        Loading stock data...
      </Typography>
    </Box>
  );
}

return (
  <Box sx={{ 
    display: 'flex', 
    flexDirection: 'column',
    width: '100%', 
    height: '100%',
    position: 'relative'
  }}>
    {showLinkWarning && (
      <Alert 
        severity="info" 
        sx={{ mb: 2 }}
        onClose={() => setShowLinkWarning(false)}
      >
        For sentiment news links, please use data from after 10/29/2024 due to accuracy issues
      </Alert>
    )}
    
    <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
      <FormControlLabel
        control={
          <Switch
            checked={showDefaultTrends}
            onChange={(e) => setShowDefaultTrends(e.target.checked)}
            color="primary"
          />
        }
        label="Show Best/Worst Performers"
      />
      <FormControlLabel
        control={
          <Switch
            checked={orderByValue}
            onChange={(e) => setOrderByValue(e.target.checked)}
            color="primary"
          />
        }
        label="Order by Sentiment Value"
      />
    </Box>

    <StyledLegendContainer sx={{ flex: 1, minHeight: 0, position: 'relative' }}>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart margin={chartMargins}>
          <ReferenceLine 
            y={0} 
            stroke="#666" 
            strokeDasharray="3 3"
          />
          
          <XAxis 
            dataKey="date"
            type="category"
            name="Date"
            allowDuplicatedCategory={false}
            tickFormatter={(value) => {
              const date = new Date(value);
              return date.toLocaleDateString('en-US', {
                month: 'numeric',
                day: 'numeric'
              });
            }}
            interval={isMobile ? 2 : "preserveStartEnd"}
            height={60}
            angle={-45}
            textAnchor="end"
            tick={{ fontSize: isMobile ? '0.6rem' : '0.7rem' }}
          />
          <YAxis 
            dataKey="sentiment" 
            domain={[-100, 100]}
            tickCount={11}
            tick={<CustomYAxisTick />}
            width={isMobile ? 40 : 60}
          />
          <Tooltip content={({ active, payload }) => {
            if (active && payload && payload.length) {
              const data = payload[0].payload as ProcessedDataPoint;
              return (
                <div style={{ 
                  backgroundColor: 'white', 
                  padding: '10px', 
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                  <p><strong>Stock:</strong> {data.stock}</p>
                  <p><strong>Date:</strong> {data.displayDate}</p>
                  <p><strong>Sentiment:</strong> {data.sentiment.toFixed(2)}</p>
                  <p><strong>Source:</strong> {data.source}</p>
                  {!data.hasValidLinks && (
                    <p style={{ color: '#666', fontSize: '0.8em', fontStyle: 'italic' }}>
                      News links not available for data before 10/29/2024
                    </p>
                  )}
                  {data.hasValidLinks && (
                    <p style={{ marginTop: '8px', fontSize: '0.8em' }}>
                      Click to view article links
                    </p>
                  )}
                </div>
              );
            }
            return null;
          }} />
          <Legend 
            onClick={handleLegendClick}
            formatter={(value: string) => {
              const avg = stockStats.stockToAvg[value];
              const isTop = stockStats.topPerformers.includes(value);
              const isBottom = stockStats.bottomPerformers.includes(value);
              let label = `${value} (${avg?.toFixed(1) ?? 0})`;
              if (isTop && showDefaultTrends) label += ' 📈';
              if (isBottom && showDefaultTrends) label += ' 📉';
              return label;
            }}
            wrapperStyle={{
              fontSize: isMobile ? '0.6rem' : '0.7rem',
              maxHeight: isMobile ? '100px' : '80px',
              overflowY: 'auto',
              width: '80%',
              margin: '0 auto',
              paddingBottom: isMobile ? '40px' : '0px'
            }}
          />
          
          {getOrderedStocks.map(stock => {
            const stockData = filteredData
              .filter(d => d.stock === stock)
              .sort((a, b) => a.timestamp - b.timestamp);

            if (stockData.length === 0) return null;

            return (
              <Scatter
                key={stock}
                name={stock}
                data={stockData}
                fill={getStockColor(stock)}
                line={shouldShowTrendLine(stock) ? {
                  stroke: getStockColor(stock),
                  strokeWidth: 1,
                  strokeDasharray: '5 5',
                  opacity: LINE_OPACITY
                } : undefined}
                lineType="joint"
                opacity={getOpacity(stock)}
                onClick={(data) => handleDataPointClick(data as unknown as ProcessedDataPoint)}
                cursor="pointer"
              />
            );
          })}
        </ScatterChart>
      </ResponsiveContainer>
    </StyledLegendContainer>

    <Typography 
      variant="body2" 
      color="text.secondary" 
      sx={{ 
        mt: 2,
        px: 2,
        pb: 1,
        textAlign: 'center',
        fontSize: isMobile ? '0.7rem' : '0.8rem'
      }}
    >
      * Sentiment scores are calculated over the entire available period including non-trading days.
    </Typography>

    <Modal
      open={modalContent !== null}
      onClose={() => setModalContent(null)}
      aria-labelledby="stock-detail-modal"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : 600,
        maxHeight: '80vh',
        overflow: 'auto',
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        p: 4
      }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" component="h2">
            Stock Details
          </Typography>
          <IconButton onClick={() => setModalContent(null)} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        
        {modalContent && (
          <>
            <Typography variant="body1" paragraph>
              <strong>Stock:</strong> {modalContent.stock}
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Date:</strong> {modalContent.date}
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Sentiment Score:</strong> {modalContent.sentiment.toFixed(2)}
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Source:</strong> {modalContent.source}
            </Typography>
            {modalContent.hasValidLinks ? (
              <Box mt={2}>
                <Typography variant="body1" paragraph>
                  <strong>Source Articles:</strong>
                </Typography>
                {modalContent.link.split(';').map((link, index) => (
                  <Box key={index} mb={1}>
                    <a 
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ 
                        color: '#1976d2',
                        textDecoration: 'none',
                        display: 'block',
                        marginBottom: '8px'
                      }}
                    >
                      Article {index + 1} →
                    </a>
                  </Box>
                ))}
              </Box>
            ) : (
              <Alert severity="info" sx={{ mt: 2 }}>
                News article links are not available for data before October 29, 2024 due to accuracy issues.
              </Alert>
            )}
          </>
        )}
      </Box>
    </Modal>
  </Box>
);
});

export default AllStocksChart;