// stocks/client/src/hooks/useStockQuotes.ts

import { useState, useEffect } from 'react';
import { StockQuote } from '../types/stockTypes';
import { apiConfig } from '../services/api';

interface QuoteState {
  quotes: Record<string, StockQuote>;
  loading: boolean;
  error: string | null;
}

export const useStockQuotes = () => {
  const [state, setState] = useState<QuoteState>({
    quotes: {},
    loading: true,
    error: null
  });

  const fetchQuotes = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      
      const response = await fetch(`${apiConfig.baseUrl}/quotes`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch stock quotes');
      }

      const data = await response.json();
      
      // Convert array to record for easier lookup
      const quotesRecord = data.quotes.reduce((acc: Record<string, StockQuote>, quote: StockQuote) => {
        acc[quote.symbol] = quote;
        return acc;
      }, {});

      setState({
        quotes: quotesRecord,
        loading: false,
        error: null
      });
    } catch (err) {
      setState({
        quotes: {},
        loading: false,
        error: err instanceof Error ? err.message : 'Failed to fetch quotes'
      });
      console.error('Error fetching stock quotes:', err);
    }
  };

  useEffect(() => {
    fetchQuotes();
    
    // Refresh every minute
    const interval = setInterval(fetchQuotes, 60000);
    
    return () => clearInterval(interval);
  }, []);

  return {
    ...state,
    refetch: fetchQuotes
  };
};