// stocks/client/src/hooks/useBotData.ts

import { useState, useEffect } from 'react';
import { botApi } from '../services/botApi';
import { BotPerformance, TradingAlert } from '../types/botTypes';

export const useBotData = (symbol: string) => {
  const [performance, setPerformance] = useState<BotPerformance | null>(null);
  const [alerts, setAlerts] = useState<TradingAlert[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const [perfData, alertData] = await Promise.all([
          botApi.getBotPerformance(symbol),
          botApi.getRecentAlerts(symbol)
        ]);
        
        setPerformance(perfData);
        setAlerts(alertData);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch bot data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    
    // Set up polling for updates
    const interval = setInterval(fetchData, 60000); // Update every minute
    
    return () => clearInterval(interval);
  }, [symbol]);

  const refreshData = async () => {
    try {
      setError(null);
      const [perfData, alertData] = await Promise.all([
        botApi.getBotPerformance(symbol),
        botApi.getRecentAlerts(symbol)
      ]);
      
      setPerformance(perfData);
      setAlerts(alertData);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to refresh bot data');
    }
  };

  return {
    performance,
    alerts,
    loading,
    error,
    refreshData
  };
};