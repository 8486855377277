// stocks/client/src/components/BotDashboard.tsx

import React, { useState } from 'react';
import { 
  Paper, 
  Box, 
  Typography, 
  Grid, 
  Alert, 
  Card, 
  CardContent, 
  Chip, 
  CircularProgress,
  IconButton,
  Tooltip,
  Divider
} from '@mui/material';
import { 
  TrendingUp, 
  TrendingDown, 
  TimelineOutlined, 
  Construction, 
  ArrowUpward, 
  ArrowDownward,
  Refresh
} from '@mui/icons-material';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip as RechartsTooltip, CartesianGrid } from 'recharts';
import { useBotData } from '../hooks/useBotData';
import { useStockQuotes } from '../hooks/useStockQuotes';
import { StockQuote } from '../types/stockTypes';

interface BotMetricsCardProps {
  title: string;
  value: string | number;
  subtitle: string;
  trend?: 'up' | 'down';
}

interface TickerChipProps {
  quote: StockQuote;
}

const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
  }).format(value);
};

const formatLargeNumber = (value: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(value);
};

const TickerChip: React.FC<TickerChipProps> = ({ quote }) => (
  <Chip
    icon={quote.percentage >= 0 ? <ArrowUpward /> : <ArrowDownward />}
    label={
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {quote.symbol}
        </Typography>
        <Typography variant="body2">
          {formatCurrency(quote.value)}
        </Typography>
        <Typography 
          variant="body2" 
          color={quote.percentage >= 0 ? 'success.main' : 'error.main'}
        >
          {quote.percentage >= 0 ? '+' : ''}{quote.percentage.toFixed(2)}%
        </Typography>
      </Box>
    }
    sx={{
      bgcolor: 'background.paper',
      '& .MuiChip-icon': {
        color: quote.percentage >= 0 ? 'success.main' : 'error.main'
      },
      px: 1,
      height: 'auto',
      '& .MuiChip-label': {
        px: 1,
        py: 0.75
      }
    }}
  />
);

const LiveTickers: React.FC = () => {
  const { quotes, loading, error, refetch } = useStockQuotes();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await refetch();
    setIsRefreshing(false);
  };

  const getLastUpdateTime = () => {
    const timestamps = Object.values(quotes).map(q => new Date(q.timestamp).getTime());
    if (timestamps.length === 0) return null;
    return new Date(Math.max(...timestamps));
  };

  const lastUpdate = getLastUpdateTime();

  if (loading && !isRefreshing) {
    return (
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mb: 4 }}>
        <Alert 
          severity="error"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={handleRefresh}
              disabled={isRefreshing}
            >
              <Refresh />
            </IconButton>
          }
        >
          Failed to load stock quotes
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">
          Live Trading Pairs
        </Typography>
        <Box display="flex" alignItems="center" gap={1}>
          {lastUpdate && (
            <Typography variant="caption" color="text.secondary">
              Last updated: {lastUpdate.toLocaleTimeString()}
            </Typography>
          )}
          <Tooltip title="Refresh quotes">
            <IconButton 
              onClick={handleRefresh} 
              size="small"
              disabled={isRefreshing}
            >
              <Refresh />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {Object.values(quotes).map((quote) => (
          <Grid item xs={12} sm={6} md={4} key={quote.symbol}>
            <TickerChip quote={quote} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const MetricsCard: React.FC<BotMetricsCardProps> = ({ title, value, subtitle, trend }) => (
  <Card>
    <CardContent>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2" color="text.secondary">
          {title}
        </Typography>
        {trend && (trend === 'up' ? 
          <TrendingUp color="success" /> : 
          <TrendingDown color="error" />
        )}
      </Box>
      <Typography variant="h5" component="div" sx={{ my: 1 }}>
        {value}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {subtitle}
      </Typography>
    </CardContent>
  </Card>
);

interface BotDashboardProps {
  symbol?: string;
}

const BotDashboard: React.FC<BotDashboardProps> = ({ symbol = 'AAPL' }) => {
  const { performance, alerts, loading, error } = useBotData(symbol);

  if (loading) {
    return (
      <Box p={3}>
        <Typography variant="h6">Loading bot data...</Typography>
      </Box>
    );
  }

  if (error || !performance) {
    return (
      <Box p={3}>
        <Alert severity="error">
          {error || 'Unable to load bot data'}
        </Alert>
      </Box>
    );
  }

  const { metrics, position, weeklySnapshots } = performance;
  const latestValue = weeklySnapshots[weeklySnapshots.length - 1].value;
  const startValue = weeklySnapshots[0].value;
  const weeklyChange = ((latestValue - startValue) / startValue) * 100;

  return (
    <Box>
      <Alert 
        severity="info" 
        sx={{ 
          mb: 4,
          backgroundColor: '#e3f2fd',
          '& .MuiAlert-message': {
            width: '100%'
          }
        }}
      >
        <Box>
          <Typography variant="h6" gutterBottom>
            🔄 Model Update in Progress
          </Typography>
          <Typography variant="body1" paragraph>
            The trading model is currently frozen while we deploy Model V2 with enhanced features.
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            Model V1 Performance Stats:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography component="span" variant="body2" color="text.secondary">Total Gain: </Typography>
                <Typography 
                  component="span" 
                  variant="body1" 
                  color={metrics.currentBalance > 100000 ? 'success.main' : 'error.main'}
                  fontWeight="bold"
                >
                  {((metrics.currentBalance - 100000) / 100000 * 100).toFixed(2)}%
                </Typography>
              </Box>
              <Box>
                <Typography component="span" variant="body2" color="text.secondary">Win Rate: </Typography>
                <Typography component="span" variant="body1" fontWeight="bold">
                  {metrics.winRate}%
                </Typography>
              </Box>
              <Box>
                <Typography component="span" variant="body2" color="text.secondary">Total Trades: </Typography>
                <Typography component="span" variant="body1" fontWeight="bold">
                  {metrics.totalTrades}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography component="span" variant="body2" color="text.secondary">Initial Balance: </Typography>
                <Typography component="span" variant="body1" fontWeight="bold">
                  {formatCurrency(100000)}
                </Typography>
              </Box>
              <Box>
                <Typography component="span" variant="body2" color="text.secondary">Current Balance: </Typography>
                <Typography 
                  component="span" 
                  variant="body1" 
                  color={metrics.currentBalance > 100000 ? 'success.main' : 'error.main'}
                  fontWeight="bold"
                >
                  {formatCurrency(metrics.currentBalance)}
                </Typography>
              </Box>
              <Box>
                <Typography component="span" variant="body2" color="text.secondary">Total Gain: </Typography>
                <Typography 
                  component="span" 
                  variant="body1" 
                  color={metrics.currentBalance > 100000 ? 'success.main' : 'error.main'}
                  fontWeight="bold"
                >
                  {formatCurrency(metrics.currentBalance - 100000)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Alert>

      <LiveTickers />

      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} md={6}>
          <MetricsCard
            title="Current Balance"
            value={formatCurrency(metrics.currentBalance)}
            subtitle="Updated in real-time"
            trend={metrics.currentBalance > 100000 ? 'up' : 'down'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MetricsCard
            title="Win Rate"
            value={`${metrics.winRate}%`}
            subtitle={`${metrics.totalTrades} total trades`}
            trend={metrics.winRate > 50 ? 'up' : 'down'}
          />
        </Grid>
      </Grid>

      <Paper sx={{ p: 2, mb: 4, bgcolor: 'background.paper' }}>
        <Typography variant="h6" gutterBottom>
          Current Position
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center">
              <TimelineOutlined sx={{ mr: 1 }} />
              <Typography>
                {position.type} at {formatCurrency(position.entryPrice)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography color="text.secondary">
              Position Size: {(position.size * 100).toFixed(1)}%
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>7-Day Performance</Typography>
        <Box height={300}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart 
              data={weeklySnapshots}
              margin={{ top: 10, right: 30, left: 20, bottom: 60 }}
            >
              <defs>
                <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#1976d2" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#1976d2" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="timestamp"
                tickFormatter={(timestamp) => {
                  const date = new Date(timestamp);
                  return date.toLocaleDateString(undefined, {
                    month: 'numeric',
                    day: 'numeric'
                  });
                }}
                angle={-45}
                textAnchor="end"
                height={60}
              />
              <YAxis 
                domain={[98000, 103000]}
                tickFormatter={formatLargeNumber}
                width={90}
                tickCount={6}
              />
              <RechartsTooltip
                formatter={(value: number) => [formatLargeNumber(value), 'Portfolio Value']}
                labelFormatter={(timestamp) => {
                  const date = new Date(timestamp);
                  return date.toLocaleDateString(undefined, {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  });
                }}
              />
              <Area
                type="monotone"
                dataKey="value"
                stroke="#1976d2"
                fillOpacity={1}
                fill="url(#colorValue)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </Paper>

      <Paper sx={{ p: 2, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Recent Alerts
        </Typography>
        <Box>
          {alerts.map((alert, index) => (
            <Alert 
              key={index} 
              severity={
                alert.type === 'ENTRY' ? 'success' :
                alert.type === 'EXIT' ? 'info' :
                alert.type === 'WARNING' ? 'warning' : 'info'
              }
              sx={{ mb: 1 }}
            >
              <Typography variant="body2">
                {alert.message}
                {alert.price && ` at ${formatCurrency(alert.price)}`}
              </Typography>
              <Typography variant="caption" display="block" color="text.secondary">
                {new Date(alert.timestamp).toLocaleString()}
              </Typography>
            </Alert>
          ))}
        </Box>
      </Paper>

      <Paper sx={{ p: 3, bgcolor: '#f5f5f5' }}>
        <Box display="flex" alignItems="center" gap={1} mb={2}>
          <Construction color="primary" />
          <Typography variant="h6">
            Coming Soon: Hybrid Trading System
          </Typography>
        </Box>
        
        <Typography variant="body1" paragraph>
          Our next-generation trading system combines specialized and generalist agents working in harmony:
        </Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 2, bgcolor: 'white', borderRadius: 1 }}>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                5 Specialist Agents
              </Typography>
              <Typography variant="body2">
                Dedicated agents for AAPL, GOOGL, MSFT, NVDA, and META, each with deep understanding of individual stock patterns and behaviors.
              </Typography>
            </Box>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 2, bgcolor: 'white', borderRadius: 1 }}>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                3 Generalist Agents
              </Typography>
              <Typography variant="body2">
                Market Maker, Risk Manager, and Portfolio Manager working together to optimize overall trading strategy and risk management.
              </Typography>
            </Box>
          </Grid>
          
          <Grid item xs={12}>
            <Box sx={{ p: 2, bgcolor: 'white', borderRadius: 1, mt: 1 }}>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                Hierarchical Decision Making
              </Typography>
              <Typography variant="body2">
                Combines micro-level stock expertise with macro-level portfolio management for optimal performance across different market conditions.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default BotDashboard;