// stocks/client/src/pages/AboutPage.tsx

import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider 
} from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import BarChartIcon from '@mui/icons-material/BarChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import UpdateIcon from '@mui/icons-material/Update';

const AboutPage: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Paper elevation={0} sx={{ p: 4, borderRadius: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom fontWeight="500">
          About Bernie-Bot
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ mb: 4 }}>
          Bernie-Bot is an advanced AI-powered trading system that combines deep learning, 
          natural language processing, and technical analysis to make data-driven investment 
          decisions. Our system processes real-time market data and news sentiment to identify 
          trading opportunities while managing risk through sophisticated portfolio optimization 
          techniques.
        </Typography>

        <Divider sx={{ my: 4 }} />

        <Typography variant="h5" gutterBottom fontWeight="500">
          Technical Features
        </Typography>

        <List>
          <ListItem>
            <ListItemIcon>
              <ShowChartIcon color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="Technical Indicators" 
              secondary="Multiple SMA and EMA periods (5, 10, 20, 50, 100, 200), RSI, MACD, Bollinger Bands, ATR, and Momentum indicators"
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <TimelineIcon color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="Market Analysis" 
              secondary="Real-time processing of market data with customizable window sizes and analysis periods"
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <BarChartIcon color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="Risk Management" 
              secondary="Sophisticated position sizing, stop-loss mechanisms, and take-profit targets"
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <TrendingUpIcon color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="Portfolio Management" 
              secondary="Dynamic position sizing with maximum position limits and transaction fee optimization"
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <UpdateIcon color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="Performance Monitoring" 
              secondary="Comprehensive tracking of key metrics including returns, volatility, and risk-adjusted performance"
            />
          </ListItem>
        </List>
      </Paper>
    </Container>
  );
};

export default AboutPage;