// stocks/client/src/services/botApi.ts

import { BotPerformance, TradingAlert } from '../types/botTypes';

const USE_MOCK_DATA = true; // Toggle this when real backend is ready

const logAPICall = (method: string, endpoint: string, data?: any) => {
  console.log(`[Bot API] ${method} ${endpoint}`, data || '');
};

const logError = (method: string, endpoint: string, error: any) => {
  console.error(`[Bot API Error] ${method} ${endpoint}:`, error);
};

class BotAPI {
  private mockPerformance: BotPerformance = {
    symbol: 'AAPL',
    position: {
      type: 'LONG',
      entryPrice: 185.34,
      size: 0.15,
      timestamp: new Date().toISOString()
    },
    metrics: {
      totalTrades: 45,
      winRate: 68.5,
      avgProfit: 2.3,
      maxDrawdown: -12.4,
      sharpeRatio: 1.8,
      currentBalance: 101983.34
    },
    weeklySnapshots: [
      {
        timestamp: this.getSnapshotDate(6),
        value: 101635.22,
        action: Math.random() * 2 - 1,
        confidence: Math.random()
      },
      {
        timestamp: this.getSnapshotDate(5),
        value: 100022.35,
        action: Math.random() * 2 - 1,
        confidence: Math.random()
      },
      {
        timestamp: this.getSnapshotDate(4),
        value: 101682.15,
        action: Math.random() * 2 - 1,
        confidence: Math.random()
      },
      {
        timestamp: this.getSnapshotDate(3),
        value: 101752.35,
        action: Math.random() * 2 - 1,
        confidence: Math.random()
      },
      {
        timestamp: this.getSnapshotDate(2),
        value: 101679.31,
        action: Math.random() * 2 - 1,
        confidence: Math.random()
      },
      {
        timestamp: this.getSnapshotDate(1),
        value: 101723.34,
        action: Math.random() * 2 - 1,
        confidence: Math.random()
      },
      {
        timestamp: this.getSnapshotDate(0),
        value: 101983.34,
        action: Math.random() * 2 - 1,
        confidence: Math.random()
      }
    ],
    allTimeSnapshots: Array.from({ length: 30 }, (_, i) => ({
      timestamp: new Date(Date.now() - (29 - i) * 24 * 60 * 60 * 1000).toISOString(),
      value: 100000 * (1 + Math.sin(i/10) * 0.05 + (i/30) * 0.2),
      action: Math.random() * 2 - 1,
      confidence: Math.random()
    }))
  };

  private mockAlerts: TradingAlert[] = [
    {
      type: 'ENTRY',
      symbol: 'AAPL',
      message: 'Opened LONG position',
      timestamp: new Date().toISOString(),
      price: 185.34,
      action: 'BUY'
    },
    {
      type: 'INFO',
      symbol: 'AAPL',
      message: 'High confidence signal detected',
      timestamp: new Date(Date.now() - 3600000).toISOString()
    }
  ];

  private getSnapshotDate(daysAgo: number): string {
    const now = new Date();
    const pstOffset = -8; // PST offset from UTC
    const localOffset = now.getTimezoneOffset() / 60;
    const totalOffset = pstOffset - localOffset;
    
    // Adjust current time to PST
    const pstNow = new Date(now.getTime() + totalOffset * 60 * 60 * 1000);
    const pstHour = pstNow.getHours();
    
    // If it's before 10 PM PST, use previous day
    if (pstHour < 22) {
      daysAgo += 1;
    }
    
    const date = new Date(now.getTime() - daysAgo * 24 * 60 * 60 * 1000);
    return date.toISOString();
  }

  async getBotPerformance(symbol: string): Promise<BotPerformance> {
    logAPICall('GET', `/bot/performance/${symbol}`);
    
    if (USE_MOCK_DATA) {
      console.log('[Bot API] Using mock data for performance');
      return {
        ...this.mockPerformance,
        symbol
      };
    }

    try {
      const response = await fetch(`/api/bot/performance/${symbol}`);
      if (!response.ok) {
        throw new Error('Failed to fetch bot performance');
      }
      const data = await response.json();
      logAPICall('GET', `/bot/performance/${symbol}`, data);
      return data;
    } catch (error) {
      logError('GET', `/bot/performance/${symbol}`, error);
      throw error;
    }
  }

  async getRecentAlerts(symbol: string): Promise<TradingAlert[]> {
    logAPICall('GET', `/bot/alerts/${symbol}`);
    
    if (USE_MOCK_DATA) {
      console.log('[Bot API] Using mock data for alerts');
      return this.mockAlerts.map(alert => ({
        ...alert,
        symbol
      }));
    }

    try {
      const response = await fetch(`/api/bot/alerts/${symbol}`);
      if (!response.ok) {
        throw new Error('Failed to fetch alerts');
      }
      const data = await response.json();
      logAPICall('GET', `/bot/alerts/${symbol}`, data);
      return data;
    } catch (error) {
      logError('GET', `/bot/alerts/${symbol}`, error);
      throw error;
    }
  }
}

export const botApi = new BotAPI();